/* Popup.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-container {
    width: 350px;
    background-color: white;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    position: relative;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
  }
  
  .back-button, .wallet-button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .title {
    font-weight: bold;
  }
  
  .content {
    padding: 16px;
  }
  
  .event-header {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    margin-bottom: 8px;
  }
  
  .event-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .orders {
    background-color: #f9f9f9;
    padding: 8px;
    border-radius: 8px;
  }
  
  .order-header {
    text-align: center;
    margin-bottom: 8px;
  }
  
  .order-header-text {
    font-size: 14px;
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 4px;
  }
  
  .order-list {
    margin-top: 8px;
  }
  
  .order {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .order span {
    flex: 1;
    text-align: center;
  }
  
  .set-price {
    margin-top: 16px;
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  .set-price-text {
    font-weight: bold;
  }
  
  .price-slider {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  
  .minus-button, .plus-button {
    background-color: #ddd;
    border: none;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider {
    flex: 1;
    margin: 0 8px;
  }
  
  .price-value {
    font-weight: bold;
  }
  
  .exit-amount {
    margin-top: 16px;
    text-align: center;
  }
  
  .amount-text {
    font-size: 24px;
    font-weight: bold;
  }
  
  .amount-label {
    background-color: yellow;
    padding: 4px;
    border-radius: 4px;
    font-weight: bold;
    margin-top: 4px;
    display: block;
  }
  
  .exit-button {
    margin-top: 16px;
    background-color: black;
    color: white;
    padding: 12px;
    width: 100%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .exit-button:hover {
    background-color: #333;
  }
  