/* src/SwipeButton.css */
.swipe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .swipe-button {
    display: flex;
    align-items: center;
    width: 95%;
    height: 50px;
    background-color: #197bff;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    user-select: none;
  }
  
  .button-text {
    flex-grow: 1;
    text-align: center;
    color: rgb(235, 235, 235);
    font-size: 13px;
    letter-spacing: 1px;
  }
  
  .circle-button {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 5px;
    top: 5px;
    cursor: pointer;

  }
  
  .circle-arrow {
    font-size: 16px;
    color: #197bff;
    font-weight: bold;
  }
  