.slide-container {
  width: 100% !important;
  position: relative;
}

.slide-bar {
  width: 80% !important;
  height: 50px;
  background-color: rgb(97, 97, 97);
  position: relative;
  margin: auto;
  border: 1px;
  border-radius: 50px;
}

.slide-button {
  width: 50px;
  height: 50px;
  background-color: rgb(216, 216, 216);
  border-radius: 50%;
  position: absolute;
  top: 0;
  transition: left 0.5s ease; /* Add transition for smooth sliding */
  cursor: pointer;
}

.slide-button.pressed {
  background-color: #555;
}

.expermnt{
  color: #353535;
}

